<template>
  <div id="app">
    <NavBar />
      <router-view />
  </div>
</template>

<script>

  import axios from 'axios';
  import NavBar from '@/components/Nav.vue'
  import { ref } from 'vue'
  
  const axiosResponse = ref(0);

  export default {
    name: 'App',

    components: { NavBar },

    async created() {
      await axios.get('/user')
        .then(response => {
          var $user = response.data
          this.$store.dispatch('user', response.data)
          this.$i18n.locale = "en"
          if($user) { if($user.localize) { this.$i18n.locale = $user.localize } }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) { axiosResponse.value = error.toJSON() }         //if (error.response.status === 401) { error = null; } <- works!
          }
          else if (error.request) { axiosResponse.value = error.toJSON() }                      //else if (error.request) { error = null; } <- works!
        }
      )
    },
  }
</script>

<style>
  /*@import url('https://fonts.googleapis.com/css/?family=Fira+Sans:400,500,600,700,800');*/

  * {
    box-sizing: border-box;
  }

  body {
    background: #acaaaa !important;
    min-height: 100vh;
    display: flex;
    font-family: Aptos, Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    /*font-family: 'Fira Sans', sans-serif;*/
    /*font-weight: 400;*/
  }

  /*
  h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;

  }
  */
  h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
    /*font-size: large;*/
  }


  body, html, #app, #root, .auth-wrapper, .auth-wrapper-home, .auth-wrapper-login, .auth-wrapper-register, .auth-wrapper-forgot, .auth-wrapper-reset {
    width: 100%;
    height: 100%;
  }

  #app {
    text-align: center;
  }

  .footer {
    background: #acaaaa !important;
  }

  .form-control {
    font-size: small;
  }

  .v-input {
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .v-input input {
    font-size: small;
  }

  .v-label {
    font-size: small;
  }

  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    height: 44px;
  }

  .auth-wrapper, .auth-wrapper-home, .auth-wrapper-login, .auth-wrapper-register, .auth-wrapper-forgot, .auth-wrapper-reset {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }

  .auth-inner, .auth-inner-home, .auth-inner-login, .auth-inner-register, .auth-inner-forgot, .auth-inner-reset {
    margin: auto;
    justify-content: center;
    background: #ffffff;
    padding: 30px 45px 35px 45px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }

  .auth-wrapper .form-control:focus{
    border-color: #167bff;
    box-shadow: none;
  }

  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 10px;
  }

  .custom-control-label {
    /*font-weight: 400;*/
  }

  .forgot-password, .forgot-password a {
    text-align: right;
    /*font-size: 13px;*/
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }

  .forgot-password a {
    color: #167bff;
  }

  .selectLanguage {
    color: #ff0000;
    margin: 0px;
    padding: 0px;
    height: 64px;
    max-width:64px;
  }
</style>

//------------------------------------------------------------------------------------------------------------------
// Scripts
//------------------------------------------------------------------------------------------------------------------
import router from './router'
import './axios'
import store from './vuex'

//------------------------------------------------------------------------------------------------------------------
// Vue-Cli
//------------------------------------------------------------------------------------------------------------------
import { createApp } from 'vue'

//------------------------------------------------------------------------------------------------------------------
// Vue-CountryFlag
//------------------------------------------------------------------------------------------------------------------
//import Vue from 'vue'
//import CountryFlag from 'vue-country-flag'
//Vue.component('country-flag', CountryFlag)
//createApp.component('country-flag', CountryFlag)
// obigen haben nicht funktioniert
import FlagIcon from 'vue-flag-icon'    // https://www.npmjs.com/package/vue-flag-icon?activeTab=readme

//------------------------------------------------------------------------------------------------------------------
// vue-i18n
//------------------------------------------------------------------------------------------------------------------
import { createI18n } from 'vue-i18n'
import en from "@/lang/translations/translations_en.json";
import de from "@/lang/translations/translations_de.json";
//  import messages from './language'

const i18n = createI18n({
  legacy: false,
  locale: "de",
  fallbackLocale: "en",
  //locale: import.meta.env.VITE_DEFAULT_LOCALE,
  //fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages: {
    en,
    de,
  },
})

//------------------------------------------------------------------------------------------------------------------
// Vuetify
//------------------------------------------------------------------------------------------------------------------
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components, directives, theme: {
    iconfont: 'mdi', // 'md' || 'mdi' || 'fa' || 'fa4'
    defaultTheme: 'light',
  },
})

//------------------------------------------------------------------------------------------------------------------
// App Components
//------------------------------------------------------------------------------------------------------------------
import App from './App.vue'

const app = createApp(App)
app.config.productionTip = false
app.use(router)
app.use(store)            // Install the store instance as a plugin
app.use(vuetify)
app.use(i18n)             // Multilanguage-Settings
app.use(FlagIcon)         // Vue-CountryFlag
app.mount("#app")

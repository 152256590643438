import { createStore } from 'vuex'

const state = {
  user: null,
  animal: null,
};

// Create a new store instance.
const store = createStore({
  state,
  getters: {
    user: (state) => {
      return state.user;
    },
    animal: (state) => {
      return state.animal;
    },
  },
  actions: {
    user(context, user) {
      context.commit('user', user);
    },
    animal(context, animal) {
      context.commit('animal', animal);
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    animal(state, animal) {
      state.animal = animal;
    }
  },
})

export default store;

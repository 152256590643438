<template>
  <nav class="navbar navbar-expand navbar-light fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand" style="font-size:medium">Home</router-link>
      <router-link to="/zoo2dashboard" class="nav-link" v-if="appZoo2Birthbook && user">Zoo2-Birthbook</router-link>
      <!--<router-link to="/Test" class="nav-link">Test</router-link>-->
      <div class="collapse navbar-collapse">
        <!-- ----------------------------------------------------------------------------------
    # NOT logged in: ab hier das Menü, wenn der User NICHT eingeloggt ist
    ----------------------------------------------------------------------------------- -->
        <ul class="navbar-nav ml-auto" v-if="!user">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">{{ $t("message.appLoginFirst") }}</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link"></a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" :key="secondsKey"> {{ clockDate }} {{ clockTime }}</a>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">{{ $t("message.appLoginLogin") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/register" class="nav-link">{{ $t("message.appLoginRegister") }}</router-link>
          </li>
        </ul>
        <!-- ----------------------------------------------------------------------------------
    # LOGGED IN: ab hier das Menü, wenn der User eingeloggt ist
    ----------------------------------------------------------------------------------- -->
        <ul class="navbar-nav ml-auto" v-if="user">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">{{ user.name }}</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link"></a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" :key="secondsKey"> {{ clockDate }} {{ clockTime }}</a>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">{{ $t("message.appLoginAbout") }}</router-link>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" @click="handleClick" class="nav-link">{{ $t("message.appLoginLogout") }}</a>
          </li>
        </ul>
      </div>
      <v-select class="selectLanguage"
                v-model="languageModel"
                :items="languages"
                item-title="name"
                item-value="name"
                bg-color="white"
                flat
                variant="plain"
                chips
                item-props
                @update:modelValue="setLanguage">
        <template v-slot:chip="{ props, item }">
          <v-chip v-bind="props" :text="item.raw.name"><flag :iso="item.raw.flag"></flag></v-chip>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :subtitle="item.raw.name" title="">
            <flag :iso="item.raw.flag"></flag>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </nav></template>

<script>

  import {mapGetters} from 'vuex'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'NavBarVue',

    data() {
      /*
      const flags = {
        1: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        2: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        3: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        4: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        5: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      }
      */

      return {
        appZoo2Birthbook: true,
        languageModel: ['de'],
        //languages: ["de", "en", "fr", "it", "es", "nl"],
        items: [
          {
            title: 'de',
            flag: 'de',
            prependIcon: 'mdi-clock-outline',
          },
          {
            title: 'en',
            flag: 'gb',
            prependIcon: 'mdi-clock-outline',
          },
          {
            title: 'fr',
            flag: 'fr',
            prependIcon: 'mdi-clock-outline',
          },
          {
            title: 'it',
            flag: 'it',
            prependIcon: 'mdi-clock-outline',
          },
          {
            title: 'es',
            flag: 'es',
            prependIcon: 'mdi-clock-outline',
          },
          {
            title: 'nl',
            flag: 'nl',
            prependIcon: 'mdi-clock-outline',
          },
        ],
        /*
        friends: ['de'],
        people: [
          { name: 'de', group: 'Group 1', avatar: flags[1], flag: 'de' },
          { name: 'en', group: 'Group 1', avatar: flags[2], flag: 'gb' },
          { name: 'fr', group: 'Group 1', avatar: flags[3], flag: 'fr' },
          { name: 'it', group: 'Group 1', avatar: flags[2], flag: 'it' },
          { name: 'es', group: 'Group 2', avatar: flags[4], flag: 'es' },
          { name: 'nl ', group: 'Group 2', avatar: flags[5], flag: 'nl' },
        ],
        */
        languages: [
          { name: 'de', flag: 'de' },
          { name: 'en', flag: 'gb' },
          { name: 'fr', flag: 'fr' },
          { name: 'it', flag: 'it' },
          { name: 'es', flag: 'es' },
          { name: 'nl', flag: 'nl' },
        ],

      }
    },

    methods: {

      handleClick() {
        localStorage.removeItem('token');
        this.$store.dispatch('user', null);
        this.$router.push('/login');
      },

      setLanguage() {
        this.$i18n.locale = this.languageModel          //this.$i18n.locale = "en"
        //this.$i18n.locale = this.friends          //this.$i18n.locale = "en"
        console.log("Sprache wurde umgestellt zu " + this.languageModel)
      },

    },

    computed:{
      ...mapGetters(['user'])
    },

    setup() {
      //const { t } = useI18n()
      const { t, locale } = useI18n({ useScope: 'global' })
      locale.value = 'de'
      return { t }
    },

  }

</script>

<script setup>

  import { ref } from 'vue'
  const secondsKey  = ref(0);
  var clockTime     = 'clockTime';
  var clockDate     = 'clockDate';
  var week = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'];

  function zeroPadding(num, digit) {
    var zero = '';
    for (var i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }

  function updateTime() {
    var cd = new Date();
    clockTime = zeroPadding(cd.getHours(), 2) + ':' + zeroPadding(cd.getMinutes(), 2) + ':' + zeroPadding(cd.getSeconds(), 2);
    clockDate = week[cd.getDay()] + ' ' + zeroPadding(cd.getDate(), 2) + '.' + zeroPadding(cd.getMonth() + 1, 2) + '.' + zeroPadding(cd.getFullYear(), 4);
    secondsKey.value++;
  }
  updateTime();
  setInterval(function () { updateTime(); }, 1000); // alle 1 Sekunden

</script>
import { createRouter, createWebHistory } from "vue-router";
//import RouterView from "@/components/RouterView.vue";
//import { i18n } from "./main.js";


const routes = [
  //{ path: '/:pathMatch(.*)', redirect: "/de" },
  { path: "/", name: "Home", component: () => import('@/components/Home.vue'), },
  { path: "/about", name: "About", component: () => import('@/components/About.vue'), },
  { path: "/register", name: "Register", component: () => import('@/components/Register.vue'), },
  { path: "/login", name: "Login", component: () => import('@/components/Login.vue'), },
  { path: "/forgotPassword", name: "ForgotPassword", component: () => import('@/components/ForgotPassword.vue'), },
  { path: "/reset/:token", name: "ResetPassword", component: () => import('@/components/ResetPassword.vue'), },
  { path: "/zoo2dashboard", name: "Zoo2Dashboard", component: () => import('@/components/Zoo2/Zoo2Dashboard.vue'), },
  //{ path: "/Test", name: "Test", component: () => import('@/components/Test/TestParent.vue'), },
  /*
  { path: "/:lang", component: () => import('@/components/Home.vue'),
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      if (!["en", "de"].includes(lang)) return next("de");
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      return next();
    }
  },
  */
 /*
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    path: "/about",
    name: "about", 
    component: () =>
      import( "./components/About.vue"),
      //import(webpackChunkName: "./components/AboutView.vue"), <- ergibt einen Fehler
  },
  */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('token');

  // If the user is not logged in, redirect to /login
  if (to.name === "Home" && token === null) {
    console.log(token);
    return next({ path: "/login/" });
  }
  next();
});

export default router;
